import Icofont from 'react-icofont';
import OwlCarousel from "react-owl-carousel3";
import PropTypes from "prop-types";

export const Testimonials = (props) => {
  return (
    <section className="testimonials ptb-100">
      <div className="container">
        <OwlCarousel
          className="owl-theme testimonial-slides"
          items={1}
          nav={true}
          dots={false}
          autoplay={false}
          loop={true}
          autoplayHoverPause={true}
          smartSpeed={1000}
          navText={[
            "<i class='icofont-arrow-left'></i>",
            "<i class='icofont-arrow-right'></i>"
          ]}
        >
          {
            props.testimonialsData.map((testimonials, index) => (
              <div className="single-testimonial-item text-center" key={index}>
                <Icofont icon="icofont-quote-left" />
                <p>{testimonials.Content}</p>

                <div className="client-profile" style={{borderRadius: "100%"}}>
                  <img src={testimonials.clientImage} alt="client" />
                </div>

                <div className="client-info">
                  <h3>{testimonials.Name}</h3>
                  <span>{testimonials.Profession}</span>
                </div>
              </div>
            ))
          }
        </OwlCarousel>
      </div>
    </section>
  );
}

//Props Types
Testimonials.propTypes = {
  testimonialsData: PropTypes.array
};

//Default Props
Testimonials.defaultProps = {
  testimonialsData: [
    {
      clientImage: require("../assets/img/avatar2.png"),
      Content: "Si buscan servicio y eficiencia, definitivamente es el mejor!!! La disponibilidad ni se diga, siempre es un gusto trabajar con personas que te ayuden hasta en el mas minimo detalle.",
      Name: "Teresa Ortega",
      Profession: "Gerente general",
    },
    {
      clientImage: require("../assets/img/avatar1.png"),
      Content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.",
      Name: "Abraham Moncada",
      Profession: "Ingeniero de manufactura",
    },
    {
      clientImage: require("../assets/img/avatar2.png"),
      Content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.",
      Name: "Cynthia Balderrama",
      Profession: "Dueña - AMTO IND",
    },
  ]
};
