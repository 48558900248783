import { useState } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import ScrollAnimation from 'react-animate-on-scroll';
import { api } from "../helpers/axios";

export const Contact = (props) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    telephone: "",
    message: "",
    successMsg: ""
  });

  const handleForm = async (e) => {
    try {
      await api.post("/email/contact", state);
      document.getElementById("contactForm").reset();
      setState({
        name: "",
        email: "",
        subject: "",
        telephone: "",
        message: "",
        successMsg: "Nos comunicaremos contigo lo mas pronto posible !!!"
      });
    } catch (error) {
      setState({
        ...state,
        successMsg: "Lo siento, ha sucedido un error"
      });
      console.log(error)
    }
  };

  const handleFields = (e) => setState({ ...state, [e.target.name]: e.target.value });

  return (
    <section id="contact" className="contact-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="section-title">
                <h2>{props.sectionTitle}</h2>
                <p>{props.sectionDescription}</p>
                <span className="section-title-bg">{props.SectionbgTitle}</span>
              </div>
            </ScrollAnimation>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="address-area">
              <div className="addess">
                <Icofont icon="icofont-google-map" />
                <h4>{props.AddTitle}</h4>
                <p>{props.Address}</p>
              </div>
              <div className="email">
                <Icofont icon="icofont-email" />
                <h4>{props.EmailTitle}</h4>
                <p>{props.Email}</p>
              </div>
              <div className="phone">
                <Icofont icon="icofont-phone" />
                <h4>{props.PhoneTitle}</h4>
                <p>{props.Phone}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-8">
            <div className="contact-form">
              <ValidationForm
                id="contactForm"
                onSubmit={(e, formData, inputs) => {
                  e.preventDefault();
                  handleForm(formData);
                }}
              >
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <TextInput
                        name="name"
                        value={state.name}
                        required
                        successMessage=""
                        errorMessage="Por favor escribe tu nombre"
                        className="form-control"
                        placeholder="Nombre"
                        autoComplete="off"
                        onChange={
                          handleFields
                        }
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <TextInput
                        name="email"
                        value={state.email}
                        type="email"
                        required
                        successMessage=""
                        errorMessage="Por favor escribe tu correo electronico"
                        className="form-control"
                        placeholder="Correo electronico"
                        autoComplete="off"
                        onChange={
                          handleFields
                        }
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <TextInput
                        name="subject"
                        value={state.subject}
                        type="text"
                        successMessage=""
                        errorMessage="Por favor escribe el asunto"
                        className="form-control"
                        required
                        placeholder="Asunto"
                        autoComplete="off"
                        onChange={
                          handleFields
                        }
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <TextInput
                        name="telephone"
                        value={state.telephone}
                        type="text"
                        successMessage=""
                        errorMessage="Por favor escribe tu telefono"
                        className="form-control"
                        required
                        placeholder="Telefono"
                        autoComplete="off"
                        onChange={
                          handleFields
                        }
                      />
                    </div>
                  </div>


                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <TextInput
                        name="message"
                        value={state.message}
                        multiline
                        placeholder="Tu mensaje"
                        className="form-control"
                        required
                        successMessage=""
                        errorMessage="Por favor escribe tu mensaje"
                        rows="5"
                        autoComplete="off"
                        onChange={
                          handleFields
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="center-wrap">
                  <button type="submit">
                    <div className="button btn-a">
                      Enviar
                      <Icofont icon="icofont-long-arrow-right" />
                      <div className="mask"></div>
                    </div>
                  </button>
                </div>
                <div className="clearfix" />
              </ValidationForm>
              {state.successMsg !== "" ? (
                <div className="col-md-12">
                  <div id="contact_send_status">
                    <h3 className="contactMsg">
                      {state.successMsg}
                    </h3>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

//Props Types
Contact.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  AddTitle: PropTypes.string,
  Address: PropTypes.string,
  EmailTitle: PropTypes.string,
  Email: PropTypes.string,
  PhoneTitle: PropTypes.string,
  Phone: PropTypes.string,
};

//Default Props
Contact.defaultProps = {
  SectionbgTitle: "Contacto",
  sectionTitle: "Nuestro Contacto",
  sectionDescription: "Si quieres mejorar la productividad de tu negocio no dudes en buscarnos para ofrecerte la solucion que necesitas.",
  AddTitle: "Direccion",
  Address: "Chihuahua, Chih, Mx.",
  EmailTitle: "Correo Electronico",
  Email: "Eber.GutierrezR@outlook.com",
  PhoneTitle: "Telefono",
  Phone: "614-120-9120",
};