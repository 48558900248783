import OwlCarousel from "react-owl-carousel3";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-scroll";

export const BannerOne = (props) => {

  return (
    <div id="home" className="hompage-slides-wrapper">
      <OwlCarousel
        className="owl-theme homepage-slides"
        items={1}
        touchDrag={false}
        margin={0}
        mouseDrag={false}
        smartSpeed={1000}
        dotData={true}
        dotsContainer={".owl-thumbs"}
      >
        {
          props.banneronesData.map(
            (bannerone, index) => (
              <div className={bannerone.BgClass} key={index}>
                <div className="diplay-table">
                  <div className="display-table-cell">
                    <VisibilitySensor>
                      {({ isVisible }) => (
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-7">
                              <span
                                className={
                                  isVisible
                                    ? "hero-text animated fadeInDown slow opacityOne"
                                    : "opacityZero"
                                }
                              >
                                {bannerone.TopTitle}
                              </span>

                              <h1
                                className={
                                  isVisible
                                    ? "animated fadeInDown slow opacityOne"
                                    : "opacityZero"
                                }
                              >
                                {bannerone.Title}
                              </h1>
                              <p
                                className={
                                  isVisible
                                    ? "animated fadeInDown slow opacityOne"
                                    : "opacityZero"
                                }
                              >
                                {bannerone.Content}
                              </p>
                              <div className="center-wrap">
                                <Link
                                  to={bannerone.BtnLink}
                                  className="btn-a"
                                  spy={true}
                                  smooth={true}
                                  offset={-65}
                                  duration={800}
                                >
                                  <div className="button">
                                    {bannerone.BtnName}
                                    <Icofont icon="icofont-long-arrow-right" />
                                    <div className="mask" /></div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
              </div>
            ))
        }
      </OwlCarousel>

      <div className="owl-thumbs">
        {
          props.thumbsData.map((thumb, index) => (
            <div className="owl-thumb-item" key={index}>
              <Icofont icon={thumb.ThumbIcon} />
              <h3>{thumb.ThumbTitle}</h3>
              <p>{thumb.ThumbContent}</p>
            </div>
          )
          )
        }
      </div>
    </div>
  );
}

//Props Types
BannerOne.propTypes = {
  banneronesData: PropTypes.array,
  thumbsData: PropTypes.array
};

//Default Props
BannerOne.defaultProps = {
  banneronesData: [
    {
      BgClass: "single-slider-item slide-bg-1",
      TopTitle: "Rendimiento",
      Title: "React, Nodejs, .Net, C#, SQL Server, MySQL, etc.",
      Content:
        `Trabaja con las tecnologias mas recientes y estables de la industria. Da el salto tecnologico que
          tu negocio necesita para mantenerse a la vanguardia`,
      BtnLink: "contact",
      BtnName: "Empezar"
    },
    {
      BgClass: "single-slider-item slide-bg-2",
      TopTitle: "Caracteristicas Unicas",
      Title: "Software A La Medida",
      Content:
        `Contamos con el conocimiento necesario para desarrollar la herramienta que mejor se ajuste a tus procesos,
         obten la verdadera solucion a tus deficiencias y lleva a tu empresa al siguiente nivel.`,
      BtnLink: "contact",
      BtnName: "Empezar"
    },
    {
      BgClass: "single-slider-item slide-bg-3",
      TopTitle: "Soporte Confiable",
      Title: "La Experiencia A Tu Disposicion",
      Content:
        `Siempre en contacto con nuestros clientes para aclarar sus dudas, pendientes de la industria para 
        asesorarte de la mejor manera y cuidar de tus intereses.`,
      BtnLink: "contact",
      BtnName: "Empezar"
    }
  ],
  thumbsData: [
    {
      ThumbIcon: "icofont-rocket-alt-1",
      ThumbTitle: "Rendimiento",
      ThumbContent: "Obtén sistemas que trabajan de una manera excepcional.",
    },
    {
      ThumbIcon: "icofont-box",
      ThumbTitle: "Caracteristicas Unicas",
      ThumbContent: "Desarrollamos las herramientas de acuerdo a tus necesidades y requerimientos, software a la medida.",
    },
    {
      ThumbIcon: "icofont-live-support",
      ThumbTitle: "Soporte Confiable",
      ThumbContent: "Nos esforzamos por aceptar e impulsar el cambio en nuestra industria, lo que nos permite mantener la relevancia de nuestros clientes.",
    },
  ]
};