import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import { Accordion, AccordionItem } from "react-sanfona";
import { Link } from "react-scroll";
import ScrollAnimation from 'react-animate-on-scroll';

export const FAQ = (props) => {
  return (
    <section className="faq ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="section-title">
                <h2>{props.sectionTitle}</h2>
                <p>{props.sectionDescription}</p>
                <span className="section-title-bg">{props.SectionbgTitle}</span>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className="faq-content">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <Link
                to={props.ContentLink}
                spy={true}
                smooth={true}
                offset={-65}
                duration={800}
              >
                <div className="content-box color-effect-1">
                  <h3>{props.ContentTitle}</h3>

                  <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
                    <div className="box-icon"><Icofont icon="icofont-share-alt" /></div>
                  </div>

                  <p>{props.ContentDescription}</p>
                </div>
              </Link>
            </div>

            <div className="col-lg-8">
              <Accordion
                rootTag="div"
                className="panel-group"
              >
                {
                  props.faqData.map(item => {
                    return (
                      <AccordionItem
                        key={item}
                        title={item.title}
                        expanded={true}
                        expandedClassName=""
                        className="panel-title panel panel-default"
                        titleTag="a"
                        titleClassName=""
                      >
                        <div>
                          <div className="panel-body">
                            <p>{item.description}</p>
                          </div>
                        </div>
                      </AccordionItem>
                    );
                  })
                }
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

//Props Types
FAQ.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  ContentLink: PropTypes.string,
  ContentTitle: PropTypes.string,
  ContentDescription: PropTypes.string,
  faqData: PropTypes.array
};

//Default Props
FAQ.defaultProps = {
  SectionbgTitle: "FAQ",
  sectionTitle: "Preguntas Fecuentes",
  sectionDescription:
    "Dudas y preguntas mas comunes.",

  ContentTitle: "Otra pregunta?",
  ContentDescription: `Si tu tienes mas preguntas, envianos un mensaje y nosotros las responderemos tan 
    pronto como sea posible.`,
  ContentLink: "contact",

  faqData: [
    {
      title: "¿Necesito tener un departamento de sistemas?",
      description: `No, si tu empresa no cuenta con un departamento de sistemas nosotros te podemos asesorar
        y ofrecerte la mejor alternativa que se ajuste a ti.`,
    },
    {
      title: "¿Necesito tener servidores propios?",
      description: `Nosotros te ofrecemos la posibilidar de utilizar nuestros servidores por lo que ese
        no es una limitante, tambien te podemos adaptar la herramienta sin que dependa de un servidor externo.`,
    },
    {
      title: "¿Ofrecen ustedes asesoria?",
      description: `Asi es, si no estas seguro de la aplicacion que necesitas para atacar tus debilidades
        nosotros podemos asesorarte de acuerdo a tus procesos para encontrarles la mejor solucion posible`,
    },
    {
      title: "¿Puedo contactar al desarrollador de mi aplicacion?",
      description: `Asi es, puedes tener comunicacion con el desarrollador de tu sistema para aclarar
        dudas tecnicas o estar mas alineado con el.`,
    }
  ]
};