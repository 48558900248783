import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

export const Services = (props) => {
  return (
    <section id="services" className="services ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="section-title">
                <h2>{props.sectionTitle}</h2>
                <p>{props.sectionDescription}</p>
                <span className="section-title-bg">{props.SectionbgTitle}</span>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className="row">
          {
            props.servicesData.map((service, index) => (
              <div className="col-md-6 col-lg-4 text-center" key={index}>
                <div className="service-item">
                  <div className="glyph">
                    <Icofont icon={service.icon} />
                  </div>
                  <h3>{service.heading}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  );
}

//Props Types
Services.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
  SectionbgTitle: "Servicios",
  sectionTitle: "Servicios",
  sectionDescription:
    "Estamos a tu completa disposicion.",

  servicesData: [
    {
      icon: "icofont-automation",
      heading: "Soluciones Creativas",
      description:
        "Siempre innovando."
    },
    {
      icon: "icofont-bullseye",
      heading: "Excelentes Funcionalidades",
      description:
        "Alcanza el siguiente nivel."
    },
    {
      icon: "icofont-woman-in-glasses",
      heading: "Soporte Amigable",
      description:
        "Siempre al pendiente de ti."
    },
    {
      icon: "icofont-chart-growth",
      heading: "Posicionamiento Web",
      description:
        "Date a conocer de la mejor manera."
    },
    {
      icon: "icofont-businessman",
      heading: "Consultoria",
      description:
        "Mejora cada dia."
    },
    {
      icon: "icofont-laptop-alt",
      heading: "Diseño Y Desarrollo",
      description:
        "Haz del software tu aliado para alcanzar tus metas."
    },
  ]
};