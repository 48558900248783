import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

// Package CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

// Template CSS Style
import "../style.css";
import "../assets/css/responsive.css";
import "../assets/css/color/color-ohara.css"
import { ScrollUpBtn } from '../components/ScrollUp';
import { HomeOne } from '../pages/HomeOne';

export const AppRouter = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <CSSTransition
          timeout={{ enter: 900, exit: 900 }}
          classNames="fade"
        >
          <Routes>
            <Route path="/" element={<HomeOne />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </CSSTransition>
        <ScrollUpBtn />
      </BrowserRouter>
    </div>
  )
}
