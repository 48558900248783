import { NavBar } from "../components/NavBar";
import { BannerOne } from "../components/banner/BannerOne";
import { Services } from "../components/Services";
import { WelcomeServices } from "../components/WelcomeServices";
import { Works } from "../components/Works";
import { About } from "../components/About";
import { Team } from "../components/Team";
import { VideoArea } from "../components/VideoArea";
import { Pricing } from "../components/Pricing";
import { FunFacts } from "../components/FunFacts";
import { Testimonials } from "../components/Testimonials";
import { FAQ } from "../components/FAQ";
import { Partner } from "../components/Partner";
import { Contact } from "../components/Contact";
import { Footer } from "../components/Footer";

export const HomeOne = () => {
  return (
    <>
      <NavBar pageName="home" />
      <BannerOne />
      <Services />
      <WelcomeServices />
      <Works />
      <About />
      <Team />
      <Pricing />
      <Testimonials />
      <FAQ />
      <Partner />
      <Contact />
      <Footer />
    </>
  );
}