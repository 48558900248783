import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from "react-scroll";

export const ItemOne = (props) => {
  return (
    <div role="tabpanel" className="tab-pane fade show active" id="monthly">
      <div className="pricing-container margin-top-60">
        {
          props.itemonesData.map((itemone, index) => (

            <div className={itemone.className} key={index}>
              <div className={itemone.FeaturedClass}>
                <span className="featured">{itemone.Featured}</span>
              </div>
              <div className="plan-price">
                <h3>{itemone.planName}</h3>
                <span className="value">{itemone.price}</span>
                <span className="period">{itemone.description}</span>
              </div>

              <div className="plan-features">
                <ul>
                  <li>{itemone.content1}</li>
                  <li>{itemone.content2}</li>
                  <li>{itemone.content3}</li>
                  <li>{itemone.content4}</li>
                  <li>{itemone.content5}</li>
                  <li>{itemone.content6}</li>
                  <li>{itemone.content7}</li>
                  <li>{itemone.content8}</li>
                </ul>
                <div className="center-wrap">
                  <Link
                    to={itemone.btnlink}
                    className="btn-a"
                    spy={true}
                    smooth={true}
                    offset={-65}
                    duration={800}
                  >
                    <div className="button">
                      {itemone.BtnName}
                      <Icofont icon="icofont-long-arrow-right" />
                      <div className="mask" /></div>
                  </Link>
                </div>
              </div>
            </div>

          ))
        }
      </div>
    </div>
  );
}

ItemOne.PropsTypes = {
  itemonesData: PropTypes.array
};
ItemOne.defaultProps = {
  itemonesData: [
    {
      planName: "Por Licencia",
      className: "plan",
      description: "Compra la licencia de tu aplicacion y utilizala el tiempo que desees",
      FeaturedClass: "",
      Featured: "",
      price: "$",
      content1: "Pago unico",
      content2: "Utiliza tu infraestructura",
      content3: "Uso indefinido",
      content4: "Sin limite de usuarios",
      BtnName: "Cotizar ahora",
      btnlink: "contact",
    },
    {
      planName: "Renta",
      className: "plan featured",
      description: "Paga por la aplicacion solo el tiempo que la necesites",
      FeaturedClass: "listing-badges",
      Featured: "Recomendado",
      price: "$",
      content1: "Diseñada para ti",
      content2: "Bajo presupuesto inicial",
      content3: "Ideal para empezar",
      BtnName: "Cotizar ahora",
      btnlink: "contact",
    },
    {
      planName: "Acceso Total",
      className: "plan",
      description: "Adquiere el codigo de tu aplicacion y modificala por tus propios medios",
      FeaturedClass: "",
      Featured: "",
      price: "$",
      content1: "Modificala tu mismo",
      content2: "Acceso al codigo fuente",
      BtnName: "Cotizar ahora",
      btnlink: "contact",
    },

  ]
};