import { useState } from "react";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import { MDBContainer, MDBRow } from "mdbreact";
import Lightbox from "react-image-lightbox";


const images = [
  require("../assets/img/ecommerce.PNG"),
  require("../assets/img/viajes.PNG"),
  require("../assets/img/report-2.jpg"),
  require("../assets/img/clocking-2.png"),
  require("../assets/img/label.jpg"),
  require("../assets/img/warehouse.jpg")
];

const smallImages = [
  require("../assets/img/ecommerce.PNG"),
  require("../assets/img/viajes.PNG"),
  require("../assets/img/report-1.jpg"),
  require("../assets/img/clocking-1.png"),  
  require("../assets/img/label.jpg"),
  require("../assets/img/warehouse.jpg")
];

export const Works = (props) => {
  const [state, setState] = useState({
    photoIndex: 0,
    isOpen: false
  });
  const { photoIndex, isOpen } = state;

  return (
    <section id="works" className="our-works ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="section-title">
                <h2>{props.sectionTitle}</h2>
                <p>{props.sectionDescription}</p>
                <span className="section-title-bg">
                  {props.SectionbgTitle}
                </span>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <MDBContainer>
        <div className="mdb-lightbox no-margin">
          <MDBRow>
            <div className="col-sm-6 col-lg-4">
              <div className="work-details">
                <figure>
                  <img
                    src={smallImages[0]}
                    alt="Gallery"
                    className="img-fluid"
                  />
                  <div className="box-content">

                    <ul className="icon">
                      <li>
                        <span
                          href="ll"
                          onClick={() => setState({ photoIndex: 0, isOpen: true })}
                          className="popup-btn">
                          <Icofont icon="icofont-search-2" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </figure>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="work-details">
                <figure>
                  <img
                    src={smallImages[1]}
                    alt="Gallery"
                    className="img-fluid"
                  />
                  <div className="box-content">

                    <ul className="icon">
                      <li>
                        <span
                          href="ll"
                          onClick={() => setState({ photoIndex: 1, isOpen: true })}
                          className="popup-btn">
                          <Icofont icon="icofont-search-2" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </figure>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="work-details">
                <figure>
                  <img
                    src={smallImages[2]}
                    alt="Gallery"
                    className="img-fluid"
                  />
                  <div className="box-content">

                    <ul className="icon">
                      <li>
                        <span
                          href="ll"
                          onClick={() => setState({ photoIndex: 2, isOpen: true })}
                          className="popup-btn">
                          <Icofont icon="icofont-search-2" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </figure>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="work-details">
                <figure>
                  <img
                    src={smallImages[3]}
                    alt="Gallery"
                    className="img-fluid"
                  />
                  <div className="box-content">

                    <ul className="icon">
                      <li>
                        <span
                          href="ll"
                          onClick={() => setState({ photoIndex: 3, isOpen: true })}
                          className="popup-btn">
                          <Icofont icon="icofont-search-2" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </figure>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="work-details">
                <figure>
                  <img
                    src={smallImages[4]}
                    alt="Gallery"
                    className="img-fluid"
                  />
                  <div className="box-content">

                    <ul className="icon">
                      <li>
                        <span
                          href="ll"
                          onClick={() => setState({ photoIndex: 4, isOpen: true })}
                          className="popup-btn">
                          <Icofont icon="icofont-search-2" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </figure>
              </div>
            </div>


            <div className="col-sm-6 col-lg-4">
              <div className="work-details">
                <figure>
                  <img
                    src={smallImages[5]}
                    alt="Gallery"
                    className="img-fluid"
                  />
                  <div className="box-content">

                    <ul className="icon">
                      <li>
                        <span
                          href="ll"
                          onClick={() => setState({ photoIndex: 5, isOpen: true })}
                          className="popup-btn">
                          <Icofont icon="icofont-search-2" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </figure>
              </div>
            </div>
          </MDBRow>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            imageTitle={photoIndex + 1 + "/" + images.length}
            onCloseRequest={() => setState({ isOpen: false })}
            onMovePrevRequest={() =>
              setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </MDBContainer>
    </section>
  );
}

//Props Types
Works.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
};

//Default Props
Works.defaultProps = {
  SectionbgTitle: "Catalogo",
  sectionTitle: "Catalogo",
  sectionDescription:
    `Contamos con amplia variedad de herramientas diseñadas para atacar los principales problemas
    de la industria asi como para mejorar y fortalecer sus procesos.`,
};