import PropTypes from 'prop-types';
import { Tabs, Tab } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';

import { ItemOne } from "../components/pricingItem/ItemOne";
import { ItemTwo } from "../components/pricingItem/ItemTwo";

export const Pricing = (props) => {
  return (
      <section id="pricing" className="our-pricing ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center">
              <ScrollAnimation animateIn="fadeInUp">
                <div className="section-title">
                  <h2>{props.sectionTitle}</h2>
                  <p>{props.sectionDescription}</p>
                  <span className="section-title-bg">{props.SectionbgTitle}</span>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="tab-slider">
                <Tabs defaultActiveKey="app" id="uncontrolled-tab">
                  <Tab eventKey="app" title="Aplicaciones">
                    <ItemOne />
                  </Tab>
                  <Tab eventKey="server" title="Infraestructura">
                    <ItemTwo />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

//Props Types
Pricing.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
};

//Default Props
Pricing.defaultProps = {
  SectionbgTitle: "Precios",
  sectionTitle: "Precios",
  sectionDescription:
    `Contamos varias modalidades de servicio, de seguro una se ajusta a tu presupuesto.`,

};