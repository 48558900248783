import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel3";
import ScrollAnimation from 'react-animate-on-scroll';

export const Team = (props) => {
  return (
    <section id="team" className="our-team ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="section-title">
                <h2>{props.sectionTitle}</h2>
                <p>{props.sectionDescription}</p>
                <span className="section-title-bg">{props.SectionbgTitle}</span>
              </div>
            </ScrollAnimation>
          </div>
        </div>

        <div className="row">
          <OwlCarousel
            className="owl-theme team-slides"
            dots={false}
            autoplay={true}
            loop={true}
            margin={30}
            nav={true}
            smartSpeed={1000}
            autoplayHoverPause={true}
            navText={[
              "<i class='icofont-arrow-left'></i>",
              "<i class='icofont-arrow-right'></i>"
            ]}
            responsive={{
              0: { items: 1 },
              768: {
                items: 2
              },
              1024: {
                items: 3
              },
              1200: {
                items: 3
              }
            }}
          >
            {
              props.teamsData.map((team, index) => (
                <div className="team-box" key={index}>
                  <img src={team.Image} alt="Description" />
                  <div className="box-content">
                    <div className="box-inner-content">
                      <h3 className="title">{team.Name}</h3>
                      <span className="post">{team.Profession}</span>
                      <ul className="icon">
                        <li><Link to={team.facebookLink}><Icofont icon="icofont-facebook" /></Link></li>
                        <li><Link to={team.linkedinLink}><Icofont icon="icofont-linkedin" /></Link></li>
                        <li><Link to={team.twitterLink}><Icofont icon="icofont-twitter" /></Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))
            }
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}

//Props Types
Team.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  teamsData: PropTypes.array
};

//Default Props
Team.defaultProps = {
  SectionbgTitle: "Equipo",
  sectionTitle: "Nuestro Equipo",
  sectionDescription:
    "Ingenieros De Software.",
  teamsData: [
    {
      Image: require("../assets/img/avatar1.png"),
      Name: "Eber Gutierrez",
      Profession: "Desarrollador Web",
      facebookLink: "/#0",
      linkedinLink: "/#0",
      twitterLink: "/#0",
    },
    {
      Image: require("../assets/img/avatar1.png"),
      Name: "Eber Gutierrez",
      Profession: "Diseñador",
      facebookLink: "/#0",
      linkedinLink: "/#0",
      twitterLink: "/#0",
    },
    {
      Image: require("../assets/img/avatar1.png"),
      Name: "Eber Gutierrez",
      Profession: "Base De Datos",
      facebookLink: "/#0",
      linkedinLink: "/#0",
      twitterLink: "/#0",
    },
    {
      Image: require("../assets/img/avatar1.png"),
      Name: "Eber Gutierrez",
      Profession: "CEO",
      facebookLink: "/#0",
      linkedinLink: "/#0",
      twitterLink: "/#0",
    },

  ]
};