import { useState } from 'react';
import { Modal, Form } from "react-bootstrap";
import Icofont from 'react-icofont';

export const SearchModal = () => {
  const [state, setState] = useState({ show: false })

  const handleClose = () => {
    setState({ show: false });
  }

  const handleShow = () => {
    setState({ show: true });
  }

  return (
    <>
      <button onClick={handleShow} className="search-toggle">
        <Icofont icon="icofont-search-1" />
      </button>

      <Modal show={state.show} onHide={handleClose} className="search-modal text-center modal fade">
        <Modal.Body>
          <div className="modal-content">
            <div className="modal-body">
              <div className="header-search-form">
                <Form>
                  <input type="text" placeholder="Search" />
                  <button><Icofont icon="icofont-search-1" /></button>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}