import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import ScrollAnimation from 'react-animate-on-scroll';

export const About = (props) => {
  return (
    <section id="about" className="about-us ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="section-title">
                <h2>{props.sectionTitle}</h2>
                <p>{props.sectionDescription}</p>
                <span className="section-title-bg">{props.SectionbgTitle}</span>
              </div>
            </ScrollAnimation>
          </div>
        </div>

        <div className="row">
          {
            props.aboutsData.map((about, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="about-info">
                  <Icofont icon={about.Icon} />
                  <h3>{about.title}</h3>
                  <p>{about.content}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  );
}

//Props Types
About.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  aboutsData: PropTypes.array
};

//Default Props
About.defaultProps = {
  SectionbgTitle: "Acerca De",
  sectionTitle: "Acerca De Nosotros",
  sectionDescription:
    `Somos una empresa chihuahuanse de desarrollo de software a la medida con el objetivo de modernizar
    la industria para volverla mas competitiva.`,
  aboutsData: [
    {
      Icon: "icofont-binoculars",
      title: "Antecedentes",
      content: "Contamos con un amplio catalogo de aplicaciones creadas para grandes, medias y pequeñas empresas.",
    },
    {
      Icon: "icofont-direction-sign",
      title: "Nuestro Enfoque",
      content: "Llevar a tu negocio al siguiente nivel.",
    },
    {
      Icon: "icofont-thermometer",
      title: "Metodologia",
      content: "Trabajamos en un esquema de desarrollo agil para adecuarlo de la mejor posible manera a ti.",
    },
    {
      Icon: "icofont-users-social",
      title: "Experiencia",
      content: "Años trabajando dia con dia en el desarrollo para empresas.",
    },
    {
      Icon: "icofont-money",
      title: "Valor Por Tu Dinero",
      content: "Invierte de la mejor manera en innovacion para tu negocio y hazlo crecer.",
    },
    {
      Icon: "icofont-unique-idea",
      title: "Desarrollo Flexible",
      content: "Analizamos tus procesos e ideamos la mejor herramienta que se adapte a tu modelo de negocio.",
    },
  ]
};