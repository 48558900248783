import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "react-scroll";

export const WelcomeServices = (props) => {
  return (
    <section className="cta-area cta-bg-img">
      <div className="diplay-table">
        <div className="display-table-cell">
          <div className="container">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="row">
                <div className="col-sm-6 col-md-7 col-lg-7">
                  <div className="cta-info">
                    <h2>{props.Title}</h2>
                    <p>{props.Content}</p>

                    <div className="center-wrap">
                      <Link
                        to={props.BtnLink}
                        className="btn-a"
                        spy={true}
                        smooth={true}
                        offset={-65}
                        duration={800}
                      >
                        <div className="button">
                          {props.BtnName}
                          <Icofont icon="icofont-long-arrow-right" />
                          <div className="mask" /></div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-5 cta-img">
                  <img className="img-fluid" src={props.Image} alt="mac-pro" />
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
}


//Props Types
WelcomeServices.propTypes = {
  Title: PropTypes.string,
  Content: PropTypes.string,
  BtnLink: PropTypes.string,
  BtnName: PropTypes.string,
  Image: PropTypes.string,

};

//Default Props
WelcomeServices.defaultProps = {
  Title: "Ofrecemos el mejor servicio de su clase en Chihuahua para sus necesidades",
  Content: "Innovacion a la vanguardia.",
  BtnLink: "contact",
  BtnName: "Cotizar ahora",
  Image: require("../assets/img/mac-pro.png"),
};