import PropTypes from "prop-types";
import OwlCarousel from "react-owl-carousel3";
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

export const Partner = (props) => {
  return (
    <section className="our-partners ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="section-title">
                <h2>{props.sectionTitle}</h2>
                <p>{props.sectionDescription}</p>
                <span className="section-title-bg">{props.SectionbgTitle}</span>
              </div>
            </ScrollAnimation>
          </div>
        </div>

        <div className="row">
          <OwlCarousel
            className="owl-theme partners-slides"
            dots={false}
            loop={true}
            margin={100}
            autoplay={true}
            smartSpeed={1000}
            nav={true}
            navText={[
              "<i class='icofont-arrow-left'></i>",
              "<i class='icofont-arrow-right'></i>"
            ]}
            responsive={{
              0: {
                items: 2,
                margin: 30,
              },
              768: {
                items: 3
              },
              1200: {
                items: 5
              }
            }}
          >
            {
              props.partnersData.map((partner, index) => (
                <div className="single-partner-logo" key={index}>
                  <Link to={partner.partnerLink} className="logo-preview">
                    <img src={partner.partnerLogo} alt="partnerLogo" />
                  </Link>
                </div>
              ))
            }
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}

//Props Types
Partner.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  partnersData: PropTypes.array
};

//Default Props
Partner.defaultProps = {
  SectionbgTitle: "Clientes",
  sectionTitle: "Nuestros Clientes",
  sectionDescription: "Estas son algunas empresas con las que trabajamos.",
  partnersData: [
    {
      partnerLogo: require("../assets/img/partners-logo/vcs.png"),
      partnerLink: "/#0"
    },
    {
      partnerLogo: require("../assets/img/partners-logo/amto.jpg"),
      partnerLink: "/#1"
    },
    {
      partnerLogo: require("../assets/img/partners-logo/dinamro.png"),
      partnerLink: "/#2"
    },
  ]
};