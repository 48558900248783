import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';

export const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <p className="copyright">{props.copyrightText}</p>
          </div>
          <div className="col-md-7">
            <div className="social-icons bottom">
              <ul className="list-inline">
                <li>{props.socialTitle} </li>
                <li>
                  <a href={props.FacebookLink} target="_blank">
                    <Icofont icon="icofont-facebook" />
                  </a>
                </li>
                <li>
                  <a href={props.TwitterLink} target="_blank">
                    <Icofont icon="icofont-twitter" />
                  </a>
                </li>
                <li>
                  <a href={props.InstagramLink} target="_blank">
                    <Icofont icon="icofont-instagram" />
                  </a>
                </li>
                <li>
                  <a href={props.linkedinLink} target="_blank">
                    <Icofont icon="icofont-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

//Props Types
Footer.propTypes = {
  copyrightText: PropTypes.string,
  FacebookLink: PropTypes.string,
  TwitterLink: PropTypes.string,
  InstagramLink: PropTypes.string,
  linkedinLink: PropTypes.string,
};

//Default Props
Footer.defaultProps = {
  copyrightText: "© 2022 Ohara Soft. Todos los derechos reservados.",
  socialTitle: "Siguenos en:",
  FacebookLink: "https://www.facebook.com/",
  TwitterLink: "https://twitter.com/",
  InstagramLink: "https://www.instagram.com/",
  linkedinLink: "https://www.linkedin.com/",
};
