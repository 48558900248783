import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from "react-scroll";

export const ItemTwo = (props) => {
  return (
    <div role="tabpanel" className="tab-pane fade show active" id="monthly">
      <div className="pricing-container margin-top-60">
        {
          props.itemtwosData.map((itemtwo, index) => (

            <div className={itemtwo.className} key={index}>
              <div className={itemtwo.FeaturedClass}>
                <span className="featured">{itemtwo.Featured}</span>
              </div>
              <div className="plan-price">
                <h3>{itemtwo.planName}</h3>
                <span className="value">{itemtwo.price}</span>
                <span className="period">{itemtwo.description}</span>
              </div>

              <div className="plan-features">
                <ul>
                  <li>{itemtwo.content1}</li>
                  <li>{itemtwo.content2}</li>
                  <li>{itemtwo.content3}</li>
                  <li>{itemtwo.content4}</li>
                  <li>{itemtwo.content5}</li>
                  <li>{itemtwo.content6}</li>
                  <li>{itemtwo.content7}</li>
                  <li>{itemtwo.content8}</li>
                </ul>
                <div className="center-wrap">
                  <Link
                    to={itemtwo.btnlink}
                    className="btn-a"
                    spy={true}
                    smooth={true}
                    offset={-65}
                    duration={800}
                  >
                    <div className="button">
                      {itemtwo.BtnName}
                      <Icofont icon="icofont-long-arrow-right" />
                      <div className="mask" /></div>
                  </Link>
                </div>
              </div>
            </div>

          ))
        }
      </div>
    </div>
  );
}

ItemTwo.PropsTypes = {
  itemtwosData: PropTypes.array
};
ItemTwo.defaultProps = {
  itemtwosData: [
    {
      planName: "Alojamiento Web - Mensual",
      className: "plan",
      description: "Ideal para poco flujo de dinero",
      FeaturedClass: "",
      Featured: "",
      price: "$",
      content1: "Paga cada mes",
      content2: "Base de datos",
      content3: "Dominio propio",
      content4: "Acorde a tus requerimientos",
      content5: "Servidor Virtual Privado - VPS",
      BtnName: "Cotizar ahora",
      btnlink: "contact",
    },
    {
      planName: "Alojamiento Web - Anual",
      className: "plan featured",
      description: "Pago unico anual y deja de estar al pendiente",
      FeaturedClass: "listing-badges",
      Featured: "Recomendado",
      price: "$",
      content1: "Pago anual",
      content2: "Base de datos",
      content3: "Dominio propio",
      content4: "Acorde a tus requerimientos",
      content5: "Servidor Virtual Privado - VPS",
      BtnName: "Cotizar ahora",
      btnlink: "contact",
    },
  ]
};